import React, { useEffect, useState } from 'react';
import './Popup.css'; // Import CSS for styling

function Popup({ title, description, onClose }) {
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(180); // 3 minutes in seconds
  const [isDownloading, setIsDownloading] = useState(false); // State to track if a download is in progress
  const [downloadingVersion, setDownloadingVersion] = useState(''); // State to store the version being downloaded
  const [timerId, setTimerId] = useState(null); // State to store the timer ID for cleanup

  const handleOverlayClick = () => {
    onClose(); // Close the popup when clicking on the overlay
  };

  const handlePopupClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the overlay
  };

  // Close the popup when the Escape key is pressed
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const handleButtonClick = (version) => {
    if (isDownloading) return; // Prevent further clicks if a download is already in progress

    setIsDownloading(true); // Set downloading state to true
    setShowLoadingPopup(true);
    setTimeRemaining(180); // Reset time to 3 minutes
    setDownloadingVersion(version); // Set the version being downloaded

    // Set the download link based on the version
    const link = version === '4.19' 
      ? 'https://hanamuke.org/downloads/Growpai_4.19_10252024.zip' 
      : 'https://hanamuke.org/downloads/Growpai_4.70_10252024.zip';

    // Start a countdown timer
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setShowLoadingPopup(false); // Hide loading popup after countdown
          console.log(`Download started for version: ${version}`);
          window.open(link, '_blank'); // Open the download link in a new tab
          setIsDownloading(false); // Reset downloading state after download starts
          setDownloadingVersion(''); // Clear the downloading version
          return 0; // Prevent going below zero
        }
        return prev - 1; // Decrement the time
      });
    }, 1000); // Update every second

    setTimerId(timer); // Store the timer ID for cleanup

    // Clean up the timer on unmount
    return () => clearInterval(timer);
  };

  // Cleanup function to clear the timer if the component unmounts
  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [timerId]);

  // Format time remaining as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`; // Add leading zero to seconds if needed
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      {!showLoadingPopup && (
      <div className="popup-card" onClick={handlePopupClick}>
        <h3 className="popup-card__title">Info about {title}</h3>
        <p className="popup-card__description">{description}</p>
        <div className="popup-button-container">
          <a href="https://discord.gg/growpai" className="popup-button" target="_blank" rel="noopener noreferrer">
            Discord
          </a>
          <button 
            className="popup-button" 
            onClick={() => handleButtonClick('4.19')} 
            disabled={isDownloading} // Disable button if downloading
          >
            Download 4.19
          </button>
          <button 
            className="popup-button" 
            onClick={() => handleButtonClick('4.70')} 
            disabled={isDownloading} // Disable button if downloading
          >
            Download 4.70
          </button>
        </div>
      </div>
      )}
      {/* Conditional rendering of the loading spinner popup */}
      {showLoadingPopup && (
        <div className="loading-popup" onClick={handlePopupClick} style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <div className="spinner"></div>
          <h3 className="popup-card__title">Downloading Version: {downloadingVersion}</h3>
          <h4 className="popup-card__note">(NOTE: Please wait until the countdown is complete.)</h4>
          <p className="popup-card__timer">{formatTime(timeRemaining)}</p>
        </div>
      )}
    </div>
  );
}

export default Popup;