import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaHome, FaBook, FaGithub, FaDiscord, FaArrowUp } from 'react-icons/fa'; // Import the up arrow icon
import Home from './pages/Home';
import Documentation from './pages/Documentation';
import logo from './logo.png';
import './App.css';

import { Link } from 'react-router-dom'; // Import Link
import { BsPerson } from 'react-icons/bs';
import { FaPerson } from 'react-icons/fa6';
import Partner from './pages/partner';

function App() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll effect
    });
  };

  return (
    <Router>
      <div className="App">
        {/* Global Navigation Bar */}
        <nav className="top-nav">
          <div className="top-nav__logo">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="top-nav__links">
            <Link to="/">
              <FaHome /> Home
            </Link>
            <Link to="/documentation">
              <FaBook /> Documentation
            </Link>
            <Link to="https://github.com/GrowHax" target="_blank" rel="noopener noreferrer">
              <FaGithub /> GitHub
            </Link>
            <Link to="https://discord.gg/SES9tgHEHE" target="_blank" rel="noopener noreferrer">
            <FaDiscord /> Discord
          </Link>
          <Link to="/partner">
            <BsPerson /> Partner
          </Link>
          </div>
        </nav>

        {/* Page Content */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/partner" element={<Partner />} />
        </Routes>

        {/* Footer */}
        <footer className="App-footer">
          <p style={{ marginLeft: 'auto' }}>
            &copy; {new Date().getFullYear()} Hanamuke
          </p>
        </footer>

        {/* Go to Top Button */}
        <button className="go-top" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      </div>
    </Router>
  );
}

export default App;